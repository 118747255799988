import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import theme from '../../config/Theme';
import { media } from '../utils/media';
import split from 'lodash/split';
import './layout.scss';

const GlobalStyle = createGlobalStyle`
  ::selection {
    color: ${theme.colors.bg};
    background: ${theme.colors.primary};
  }
  body {
    background: ${theme.colors.modio.dark};
    color: ${theme.colors.grey.default};
    @media ${media.phone} {
      font-size: 14px;
    }
  }
  a {
    color: ${theme.colors.grey.dark};
    text-decoration: none;
    transition: all ${theme.transitions.normal};
  }
  a:hover {
    color: ${theme.colors.primary};
  }
  h1, h2, h3, h4 {
    color: ${theme.colors.grey.dark};
  }
  blockquote {
    font-style: italic;
    position: relative;
  }

  blockquote:before {
    content: "";
    position: absolute;
    background: ${theme.colors.primary};
    height: 100%;
    width: 6px;
    margin-left: -1.6rem;
  }
  label {
    margin-bottom: .5rem;
    color: ${theme.colors.grey.dark};
  }
  input, textarea {
    border-radius: .5rem;
    border: none;
    background: rgba(0, 0, 0, 0.05);
    padding: .25rem 1rem;
    &:focus {
      outline: none;
    }
  }
  .textRight {
    text-align:right;
  }

  #main_logo {
    position: absolute;
    top: 2vw;
    left: 2vw;
    width: 7vw;
  }
  @media ${media.phone}{
   #main_logo {
     width: 15vw;
   }
  }
`;
const FooterShape: any = styled.div`
  width: 100vw;
  height: auto;
  position: relative;
  margin-top: -16vw;
  left: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  img {
    width: 100vw;
    margin-bottom: 0;
  }
  .ShapeText {
    position: relative;
    padding: 0 0 0 9vw;
    margin: -5vw 0vw 0 -7vw;
    font-size: 0.6rem;
    transform: rotate(-5deg);
    color: ${theme.colors.modio.light};
    a {
      color: ${theme.colors.modio.light};
    }
  }
`;

const FooterText: any = styled.div`
  display: inline-block;
  padding: 0 1vw;
  color: ${theme.colors.white};
  a {
    color: ${theme.colors.modio.light};
  }
`;

const Footer = styled.footer`
  text-align: center;
  position: relative;
  margin: 2vw 4vw 0 0;
  line-height: 0.8vw;
  font-size: 0.8vw;
  float: right;
  padding: 0;
  span {
    font-size: 0.5vw;
  }
  img {
    height: 1.3vw;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  @media ${media.tablet} {
    padding-top: 2vw;
    font-size: 0.75rem;
    line-height: 0.75rem;
    span {
      font-size: 0.6rem;
    }
    img {
      height: 1.2rem;
    }
  }
  @media ${media.phone} {
    padding-top: 2vw;
    font-size: 0.6rem;
    line-height: 0.6rem;
    span {
      font-size: 0.5rem;
    }
    img {
      height: 1.1rem;
    }
  }
`;


export class Layout extends React.PureComponent<{}> {
  public render() {
    const { children } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query LayoutQuery{
            site {
              buildTime(formatString: "YYYY-MM-DD")
              siteMetadata {
                siteMenu {
                  name
                  link
                  icon
                }
              }
            }
          }
        `}
        render={data => (
          <ThemeProvider theme={theme}>
            <React.Fragment>
              <GlobalStyle />
              {children}
              <FooterShape><img src='/assets/footerShape.svg'/>
                <div className="ShapeText">Modio AB · Sankt Larsgatan 11 · 582 24 Linköping · <a href="mailto:solar@modio.se">solar@modio.se</a></div>
              </FooterShape>
              <Footer>
                <img src='/assets/logo_upper.svg' />
                <FooterText>
                &copy; {split(data.site.buildTime, '.')[2]} by <a href="https://www.modio.se">Modio AB</a>. All rights reserved. <br />
                <span>Last build: {data.site.buildTime}</span>
                </FooterText>
                <img src='/assets/logo_lower.svg' />
              </Footer>
            </React.Fragment>
          </ThemeProvider>
        )}
      />
    );
  }
}
