import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { darken, lighten } from 'polished';
import rgba from 'polished/lib/color/rgba';
import { media } from '../utils/media';
import config from '../../config/SiteConfig';
import theme from '../../config/Theme';


const HeaderWrapper: any = styled.header`
  position: relative;
  background: linear-gradient(
      -180deg,
      ${props => rgba(darken(0.1, props.theme.colors.primary), 0.6)},
      ${props => rgba(lighten(0, props.theme.colors.modio.dark), 1)}
    ),
    url(${(props: any) => props.banner}) no-repeat;
  background-size: cover;
  padding: 8rem 2rem 10rem;
  text-align: center;
  
  @media ${media.tablet} {
    padding: 4rem 2rem 6rem;
  }
  @media ${media.phone} {
    padding: 1rem 0.5rem 2rem;
  }
  .homeLink {
    position: absolute;
    top: 2vw;
    left: 2vw;
  }
  .contactLink {
    position: absolute;
    top: 2vw;
    right: 2vw;
  }
  .homeLink, .contactLink {
    background-color: rgba(0,0,0,0.1);
    padding: 0.5rem;
    border-radius: 50px;
    z-index: 9999;
  }
  img.homeLogo {
    width: 2vw;
    display: inline-block;
    padding: 0;
    margin: 0;
    margin-right: 1em;
    min-width: 2rem;
    vertical-align: bottom;
  }
  img.contactLogo {
    width: 2vw;
    display: inline-block;
    padding: 0;
    margin: 0;
    margin-left: 1em;
    min-width: 2rem;
    vertical-align: bottom;
  }
  @media ${media.phone} {
    img.homeLogo, img.contactLogo {
      width: 5vw;
    }
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 999;
  a {
    color: white;
    &:hover {
      opacity: 0.85;
      color: white;
    }
  }
`;

interface Props {
  children: any;
  banner?: string;
}

export class Header extends React.PureComponent<Props> {
  public render() {
    return (
      <StaticQuery
        query={graphql`
          query MenuQuery {
            site {
              siteMetadata {
                siteMenu {
                  name
                  link
                  icon
                }
              }
            }
          }
        `}
      render={data => (
      <HeaderWrapper banner={this.props.banner || config.defaultBg}>
        <a href='/' className="homeLink"><img src='/assets/home_logo.svg' alt='Start' className='homeLogo'/>Start</a>
        <a href='/contact' className="contactLink">Kontakt<img src='/assets/contact.svg' alt='Kontakt' className='contactLogo' /></a>
        <Content>{this.props.children}</Content>
      </HeaderWrapper>
      )}
      />
    );
  }
}
